import Dropdown from 'react-bootstrap/Dropdown';
import Person3Icon from '@mui/icons-material/Person3';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
function UserDetails() {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<>
			<Dropdown onClick={handleShow}>
				<Dropdown.Toggle className='unames'>
					<div className='hrPro'><img src='./imgs/u.jpg' /></div>	 Welcome : Subhash
				</Dropdown.Toggle>
			</Dropdown>
			<Offcanvas show={show} onHide={handleClose} placement='end'>
				<Offcanvas.Header closeButton className='logHeader'>
					<Offcanvas.Title>Welcome: Subhash</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className='p-0'>
					<div className='ankerTabs'>
						<Link to=""><Person3Icon /> Profile</Link>
						<Link to="/"><LogoutIcon /> Logout</Link>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}
export default UserDetails;
