import NotificationsIcon from '@mui/icons-material/Notifications';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Toast from 'react-bootstrap/Toast';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
function Emplnotification() {
	let notiNumber = 4;
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showA, setShowA] = useState(true);
	const toggleShowA = () => setShowA(!showA);
	return (
		<>
			<div className='noti'>
				<div className='notiBox' onClick={handleShow}>
					<NotificationsIcon />
					<div className='nomberOfNoti'>
						{notiNumber}{notiNumber >= 1 && <Spinner animation="grow" variant="danger" className='spiBlinker' />}
					</div>
				</div>
			</div>
			<Offcanvas show={show} onHide={handleClose} placement='end'>
				<Offcanvas.Header closeButton className='employeeNoti_logHeader'>
					Notifications
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className='mt-3 animate__animated animate__fadeInUpBig'>
						<Toast onClose={toggleShowA} className='w-100'>
							<Toast.Header>
								<strong className="me-auto">User ID : 10017</strong>
								<small>11 mins ago</small>
							</Toast.Header>
							<Toast.Body>
								Hi Sir/Mam,<br />
								Showing absent morning time on date 20/03/2024 please check.<br />

								Thanks & Regards<br />
								Subhash Chand
							</Toast.Body>
							<div className='toastFooter'>
								<btn className='btn btn-outline-success btn-sm'><TaskAltIcon /> Complete</btn>
								<btn className='btn btn-outline-danger btn-sm'><CloseIcon /> Pending</btn>
							</div>
						</Toast>
					</div>
					<div className='mt-3 animate__animated animate__fadeInUpBig'>
						<Toast onClose={toggleShowA} className='w-100'>
							<Toast.Header>
								<strong className="me-auto">User ID : 937</strong>
								<small>90 mins ago</small>
							</Toast.Header>
							<Toast.Body>
								Hi Sir/Mam,<br />
								Showing absent morning time on date 29/03/2024 please check.<br />

								Thanks & Regards<br />
								Hari Krishan Pant
							</Toast.Body>
							<div className='toastFooter'>
								<btn className='btn btn-outline-success btn-sm'><TaskAltIcon /> Complete</btn>
								<btn className='btn btn-outline-danger btn-sm'><CloseIcon /> Pending</btn>
							</div>
						</Toast>
					</div>
					<div className='mt-3 animate__animated animate__fadeInUpBig'>
						<Toast onClose={toggleShowA} className='w-100'>
							<Toast.Header>
								<strong className="me-auto">User ID : 1432</strong>
								<small>10 mins ago</small>
							</Toast.Header>
							<Toast.Body>
								Hi Sir/Mam,<br />
								Showing absent morning time on date 29/03/2024 please check.<br />

								Thanks & Regards<br />
								Raj Kumar Singh
							</Toast.Body>
							<div className='toastFooter'>
								<btn className='btn btn-outline-success btn-sm'><TaskAltIcon /> Complete</btn>
								<btn className='btn btn-outline-danger btn-sm'><CloseIcon /> Pending</btn>
							</div>
						</Toast>
					</div>
					<div className='mt-3 animate__animated animate__fadeInUpBig'>
						<Toast onClose={toggleShowA} className='w-100'>
							<Toast.Header>
								<strong className="me-auto">User ID : 1744</strong>
								<small>2 mins ago</small>
							</Toast.Header>
							<Toast.Body>
								Hi Sir/Mam,<br />
								Showing absent morning time on date 01/03/2024 please check.<br />

								Thanks & Regards<br />
								Ajay Bhati Singh
							</Toast.Body>
							<div className='toastFooter'>
								<btn className='btn btn-outline-success btn-sm'><TaskAltIcon /> Complete</btn>
								<btn className='btn btn-outline-danger btn-sm'><CloseIcon /> Pending</btn>
							</div>
						</Toast>
					</div>

				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}
export default Emplnotification;
