
import $ from 'jquery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function EmplMonthlyAtten() {
	return (
		<>


			<div className='main_wrapper shadow-sm'>
				<div className='header_month'>Your Attendance</div>
				<div className='headerRowSelection'>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Months</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Months"
							>
								<MenuItem value={1}>January</MenuItem>
								<MenuItem value={2}>February</MenuItem>
								<MenuItem value={3}>March</MenuItem>
								<MenuItem value={4}>April</MenuItem>
								<MenuItem value={5}>May</MenuItem>
								<MenuItem value={6}>June</MenuItem>
								<MenuItem value={7}>July</MenuItem>
								<MenuItem value={8}>August</MenuItem>
								<MenuItem value={9}>September</MenuItem>
								<MenuItem value={10}>October</MenuItem>
								<MenuItem value={11}>November</MenuItem>
								<MenuItem value={12}>December</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Year</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Year"
							>
								<MenuItem value={1}>2014</MenuItem>
								<MenuItem value={2}>2015</MenuItem>
								<MenuItem value={3}>2016</MenuItem>
								<MenuItem value={4}>2017</MenuItem>
								<MenuItem value={5}>2018</MenuItem>
								<MenuItem value={6}>2019</MenuItem>
								<MenuItem value={7}>2020</MenuItem>
								<MenuItem value={8}>2021</MenuItem>
								<MenuItem value={9}>2022</MenuItem>
								<MenuItem value={10}>2023</MenuItem>
								<MenuItem value={11}>2024</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='monthAnd_years'><strong>18</strong> April | <span className='years_color'>2024</span></div>
				<div className='table-responsive attend_btl'>
					<table className='table table-bordered'>
						<thead>
							<tr className='daysRow'>
								<th><div className='days_box'>SUN</div></th>
								<th><div className='days_box'>MON</div></th>
								<th><div className='days_box'>TUE</div></th>
								<th><div className='days_box'>WED</div></th>
								<th><div className='days_box'>THU</div></th>
								<th><div className='days_box'>FRI</div></th>
								<th><div className='days_box'>SAT</div></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td><div className='date_string offf'>1</div></td>
								<td><div className='date_string'>2</div></td>
								<td><div className='date_string'>3</div></td>
								<td><div className='date_string'>4</div></td>
								<td><div className='date_string'>5</div></td>
								<td><div className='date_string'>6</div></td>
								<td><div className='date_string'>7</div></td>
							</tr>
							<tr>
								<td><div className='date_string offf'>8</div></td>
								<td><div className='date_string'>9</div></td>
								<td><div className='date_string'>10</div></td>
								<td><div className='date_string'>11</div></td>
								<td><div className='date_string'>12</div></td>
								<td><div className='date_string'>13</div></td>
								<td><div className='date_string offf'>14</div></td>
							</tr>
							<tr>
								<td><div className='date_string offf'>15</div></td>
								<td><div className='date_string'>16</div></td>
								<td><div className='date_string ab'>17</div></td>
								<td><div className='date_string ab'>18</div></td>
								<td><div className='date_string'>19</div></td>
								<td><div className='date_string'>20</div></td>
								<td><div className='date_string'>21</div></td>
							</tr>
							<tr>
								<td><div className='date_string offf'>22</div></td>
								<td><div className='date_string'>23</div></td>
								<td><div className='date_string'>24</div></td>
								<td><div className='date_string'>25</div></td>
								<td><div className='date_string'>26</div></td>
								<td><div className='date_string'>27</div></td>
								<td><div className='date_string'>28</div></td>
							</tr>
							<tr>
								<td><div className='date_string offf'>29</div></td>
								<td><div className='date_string'>30</div></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>

					<div className='rowForcolm'>
						<div className='colms'> <div className='colorBox'></div> - Week Off </div>
						<div className='colms'> <div className='colorBox present'></div> - Present </div>
						<div className='colms'> <div className='colorBox absent'></div> - Absent </div>
					</div>
				</div>

				<div className='table-responsive attend_btl'>
					<table className='table table-bordered'>

						<tr className='trRowAtten_2'>
							<td>1st Half</td>
							<td>2nd Half</td>
							<td>Present</td>
							<td>Absent</td>
							<td>Tour</td>
							<td>Net-Work Hours</td>
							<td>Authorized Overtime</td>
						</tr>

						<tbody>
							<tr>
								<td>IN</td>
								<td>_</td>
								<td>15</td>
								<td>3</td>
								<td>NA</td>
								<td>NA</td>
								<td>NA</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>



		</>
	);
}
export default EmplMonthlyAtten;
