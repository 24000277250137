import React, { createContext, useState } from "react";
export const Context = createContext();
const userContext = React.createContext();

export function UserProvider({ children }) {
	const [subhash, setSubhash] = useState("");
	return (
		<>
			<userContext.Provider
				value={{
					subhash: subhash,
				}}>
				{children}
			</userContext.Provider>
		</>
	)

}

export default userContext;
