
import $ from 'jquery';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditNoteIcon from '@mui/icons-material/EditNote';
function EmplReport() {
	return (
		<>
			<div className='main_wrapper shadow-sm'>
				<div className='header_month'>View Report</div>
				<div className='headerRowSelection'>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Months</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Months"
							>
								<MenuItem value={1}>January</MenuItem>
								<MenuItem value={2}>February</MenuItem>
								<MenuItem value={3}>March</MenuItem>
								<MenuItem value={4}>April</MenuItem>
								<MenuItem value={5}>May</MenuItem>
								<MenuItem value={6}>June</MenuItem>
								<MenuItem value={7}>July</MenuItem>
								<MenuItem value={8}>August</MenuItem>
								<MenuItem value={9}>September</MenuItem>
								<MenuItem value={10}>October</MenuItem>
								<MenuItem value={11}>November</MenuItem>
								<MenuItem value={12}>December</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className='inputSelect_att'>
						<FormControl size='small' fullWidth style={{ width: "99%" }}>
							<InputLabel id="demo-simple-select-label" >Year</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Year"
							>
								<MenuItem value={1}>2014</MenuItem>
								<MenuItem value={2}>2015</MenuItem>
								<MenuItem value={3}>2016</MenuItem>
								<MenuItem value={4}>2017</MenuItem>
								<MenuItem value={5}>2018</MenuItem>
								<MenuItem value={6}>2019</MenuItem>
								<MenuItem value={7}>2020</MenuItem>
								<MenuItem value={8}>2021</MenuItem>
								<MenuItem value={9}>2022</MenuItem>
								<MenuItem value={10}>2023</MenuItem>
								<MenuItem value={11}>2024</MenuItem>
							</Select>
						</FormControl>
					</div>
				</div>
				<div className='monthAnd_years'><strong>18</strong> April | <span className='years_color'>2024</span></div>
				<div className='table-responsive attend_btl'>
					<table className='table table-bordered'>
						<thead>
							<tr className='daysRow'>
								<th><div className='days_box'>Sr.No.</div></th>
								<th><div className='days_box'>Report Date</div></th>
								<th><div className='days_box'>Submission date</div></th>
								<th><div className='days_box'>Project</div></th>
								<th><div className='days_box'>Status</div></th>
								<th><div className='days_box'>Message</div></th>
								<th><div className='days_box'>Verify report</div></th>
								<th><div className='days_box'>Edit</div></th>
								<th><div className='days_box'>Remark</div></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1.</td>
								<td>18-04-2024</td>
								<td>18-04-2024</td>
								<td>HRMS</td>
								<td><button className='btn btn-outline-secondary btn-sm'>In Progress</button></td>
								<td>Working or Employee dashboard.</td>
								<td><button className='btn  btn-sm btn-outline-info'>Verify</button></td>
								<td><EditNoteIcon/></td>
								<td>NA</td>
							</tr>
							<tr>
								<td>2.</td>
								<td>19-04-2024</td>
								<td>18-04-2024</td>
								<td>HRMS</td>
								<td><button className='btn btn-outline-success btn-sm'>Done</button></td>
								<td>Employee dashboard Atendance section done.</td>
								<td><button className='btn  btn-sm btn-outline-info'>Verify</button></td>
								<td><EditNoteIcon/></td>
								<td>NA</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>



		</>
	);
}
export default EmplReport;
