
import { Row, Col, Table } from 'react-bootstrap';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function LeaveBlance() {
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<Row>
						<Col className="col">
						<div className='menHeading_3'>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Period</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Period"
								>
									<MenuItem value={1}>January</MenuItem>
									<MenuItem value={2}>February</MenuItem>
									<MenuItem value={3}>March</MenuItem>
									<MenuItem value={4}>April</MenuItem>
									<MenuItem value={5}>May</MenuItem>
									<MenuItem value={6}>June</MenuItem>
									<MenuItem value={7}>July</MenuItem>
									<MenuItem value={8}>August</MenuItem>
									<MenuItem value={9}>September</MenuItem>
									<MenuItem value={10}>October</MenuItem>
									<MenuItem value={11}>November</MenuItem>
									<MenuItem value={12}>December</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Balance Month-Year</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Balance Month-Year"
								>
									<MenuItem value={1}>2014</MenuItem>
									<MenuItem value={2}>2015</MenuItem>
									<MenuItem value={3}>2016</MenuItem>
									<MenuItem value={4}>2017</MenuItem>
									<MenuItem value={5}>2018</MenuItem>
									<MenuItem value={6}>2019</MenuItem>
									<MenuItem value={7}>2020</MenuItem>
									<MenuItem value={8}>2021</MenuItem>
									<MenuItem value={9}>2022</MenuItem>
									<MenuItem value={10}>2023</MenuItem>
									<MenuItem value={11}>2024</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
							<Table responsive hover bordered>
								<thead>
									<tr>
										<th>Year</th>
										<th>Month</th>
										<th>Code</th>
										<th>Name</th>
										<th>Opening</th>
										<th>Credit</th>
										<th>Debit</th>
										<th>Encashment</th>
										<th>Availed</th>
										<th>Closing</th>
										<th>Overflow</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>2024</td>
										<td>Apr</td>
										<td>EL</td>
										<td>PRIVILEDGE LEAVE</td>
										<td>1.25</td>
										<td>2.25</td>
										<td>0.00</td>
										<td>0.00</td>
										<td>3.50</td>
										<td>0.00</td>
										<td>0.00</td>
									</tr>
									<tr>
										<td>2024</td>
										<td>Apr</td>
										<td>CL</td>
										<td>CASUAL LEAVE</td>
										<td>1.25</td>
										<td>2.25</td>
										<td>0.00</td>
										<td>0.00</td>
										<td>3.50</td>
										<td>0.00</td>
										<td>0.00</td>
									</tr>
									
								</tbody>
							</Table>
						</Col>
					</Row>

				</div>
			</div>
		</>
	);
}
export default LeaveBlance;
