import Accordion from 'react-bootstrap/Accordion';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Col, Row, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
function EmpAtteSummary() {

	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<div className='menHeading_2'>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Attendance Period</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Attendance Period"
								>
									<MenuItem value={1}>January</MenuItem>
									<MenuItem value={2}>February</MenuItem>
									<MenuItem value={3}>March</MenuItem>
									<MenuItem value={4}>April</MenuItem>
									<MenuItem value={5}>May</MenuItem>
									<MenuItem value={6}>June</MenuItem>
									<MenuItem value={7}>July</MenuItem>
									<MenuItem value={8}>August</MenuItem>
									<MenuItem value={9}>September</MenuItem>
									<MenuItem value={10}>October</MenuItem>
									<MenuItem value={11}>November</MenuItem>
									<MenuItem value={12}>December</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Select Year</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Select Year"
								>
									<MenuItem value={1}>2014</MenuItem>
									<MenuItem value={2}>2015</MenuItem>
									<MenuItem value={3}>2016</MenuItem>
									<MenuItem value={4}>2017</MenuItem>
									<MenuItem value={5}>2018</MenuItem>
									<MenuItem value={6}>2019</MenuItem>
									<MenuItem value={7}>2020</MenuItem>
									<MenuItem value={8}>2021</MenuItem>
									<MenuItem value={9}>2022</MenuItem>
									<MenuItem value={10}>2023</MenuItem>
									<MenuItem value={11}>2024</MenuItem>
								</Select>
							</FormControl>
						</div>
						<Button variant="contained" color="info" className=''>
							View <VisibilityIcon />
						</Button>

					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte mt-3 summaryData'>
						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
								<Accordion.Header>Summary</Accordion.Header>
								<Accordion.Body className='bodyData_summ'>
									<Row>
										<Col className='col-md-6 col-sm-12 col-12'>
											<div className='boxShadow shadow-sm'>
												<div className='rowSum'>
													<div>Presents</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Absents</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Paid Leaves</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Unpaid Leaves</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Hourly Paid Leaves</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Hourly Unpaid Leaves</div>
													<div>6.0</div>
												</div>

												<div className='rowSum'>
													<div>Tours</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Week-Offs</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Holidays</div>
													<div>6.0</div>
												</div>
											</div>
											<div className='boxShadow shadow-sm'>
												<div className='rowSum'>
													<div>Work Hours</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Extra Work</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Net-Work Hours</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Break Hours</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Authorized Overtime</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Generated Overtime</div>
													<div>6.0</div>
												</div>
											</div>

											<div className='boxShadow shadow-sm'>
												<div className='rowSum'>
													<div>Available Short Leaves</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Available Short Leave Duration</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Availed/Allowed Late-IN Duration (Min)</div>
													<div>40:00</div>
												</div>
												<div className='rowSum'>
													<div>Availed/Allowed Early-OUT Duration (Min)</div>
													<div>6.0</div>
												</div>
												<div className='rowSum'>
													<div>Availed/Allowed Less Work Duration (Min)</div>
													<div>6.0</div>
												</div>

											</div>
										</Col>
										<Col>
											<LocalizationProvider dateAdapter={AdapterDayjs} disabled>
												<DateCalendar disabled />
											</LocalizationProvider>
										</Col>
									</Row>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>Applied Leaves</Accordion.Header>
								<Accordion.Body>
									<Table responsive bordered hover>
										<thead>
											<tr>
												<th>Application Date</th>
												<th>From</th>
												<th>To</th>
												<th>Leave</th>
												<th>Posted Duration</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>UL - UNPAID LEAVE</td>
												<td>0.5</td>
												<td><div className='btn btn-outline-primary btn-sm'>Pending</div></td>
											</tr>
											<tr>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>UL - UNPAID LEAVE</td>
												<td>2.5</td>
												<td><div className='btn btn-outline-success btn-sm'>Approved</div></td>
											</tr>
											<tr>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>UL - UNPAID LEAVE</td>
												<td>1.5</td>
												<td><div className='btn btn-outline-primary btn-sm'>Pending</div></td>
											</tr>
											<tr>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>15/04/2024</td>
												<td>UL - UNPAID LEAVE</td>
												<td>0.5</td>
												<td><div className='btn btn-outline-danger btn-sm'>Reject</div></td>
											</tr>
										</tbody>
									</Table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>Applied Tours</Accordion.Header>
								<Accordion.Body>
									<div class="alert alert-warning py-2" role="alert">
										No Data
									</div>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>Applied C-OFFs</Accordion.Header>
								<Accordion.Body>
									<div class="alert alert-warning py-2" role="alert">
										No Data
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
			</div>
		</>
	);
}
export default EmpAtteSummary;
