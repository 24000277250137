import { useState, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from 'react-bootstrap/Table';
import ListIcon from '@mui/icons-material/List';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function EmplHoliday() {
	const [age, setAge] =useState('');

	const handleChange = (event) => {
	  setAge(event.target.value);
	};
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<div className='menHeading'>
						<div className='printHeading'>Holiday Schedule</div>
						<div className='secondCol'>
							<div className='field'>
								<FormControl  sx={{ m: 1, minWidth: 220 }} size="small" style={{margin: "0"}}>
									<InputLabel id="demo-simple-select-standard-label">Select Years</InputLabel>
									<Select
										value={age}
										onChange={handleChange}
										label="Select Years"
									>
										<MenuItem value={10}>Current Year</MenuItem>
										<MenuItem value={20}>Previous Year</MenuItem>
										<MenuItem value={30}>Next Year</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte'>
						<Table hover bordered responsive align=''>
							<thead>
								<tr>
									<th>Sr.N.</th>
									<th>From</th>
									<th>To</th>
									<th>Holiday</th>
									<th>Days</th>
								</tr>
							</thead>
							<tbody >
								<tr>
									<td>1</td>
									<td>26/01/2024 (Friday)</td>
									<td>26/01/2024 (Friday)</td>
									<td>REPUBLIC DAY</td>
									<td>1</td>
									
								</tr>
								<tr>
									<td>2</td>
									<td>25/03/2024 (Monday)</td>
									<td>25/03/2024 (Monday)</td>
									<td>HOLI</td>
									<td>1</td>
								</tr>
								<tr>
									<td>3</td>
									<td>15/08/2024 (Thursday)</td>
									<td>15/08/2024 (Thursday)</td>
									<td>INDEPENDENCE DAY	</td>
									<td>1</td>
								</tr>
								<tr>
									<td>4</td>
									<td>19/08/2024 (Monday)	</td>
									<td>19/08/2024 (Monday)	</td>
									<td>RAKSHA BANDHAN</td>
									<td>1</td>
								</tr>
								<tr>
									<td>5</td>
									<td>02/10/2024 (Wednesday)	</td>
									<td>02/10/2024 (Wednesday)</td>
									<td>GANDHI JAYANTI</td>
									<td>1</td>
								</tr>
								<tr>
									<td>6</td>
									<td>12/10/2024 (Saturday)	</td>
									<td>12/10/2024 (Saturday)	</td>
									<td>DUSSHERA</td>
									<td>1</td>
								</tr>
								<tr>
									<td>7</td>
									<td>01/11/2024 (Friday)		</td>
									<td>01/11/2024 (Friday)	</td>
									<td>DEEPAWALI</td>
									<td>1</td>
								</tr>
								<tr>
									<td>8</td>
									<td>02/11/2024 (Saturday)	</td>
									<td>02/11/2024 (Saturday)	</td>
									<td>GOVARDHAN PUJA		</td>
									<td>1</td>
								</tr>
								<tr>
									<td>9</td>
									<td>03/11/2024 (Sunday)	</td>
									<td>03/11/2024 (Sunday)	</td>
									<td>BHAI DOOJ</td>
									<td>1</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	);
}
export default EmplHoliday;
