import { Container, Row, Col } from "react-bootstrap";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Marquee from "react-fast-marquee";
import Carousel from 'react-bootstrap/Carousel';
import TextField from '@mui/material/TextField';
import Person3Icon from '@mui/icons-material/Person3';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState,useContext } from "react";
import LoginHeader from "./LoginHeader";
import { useNavigate } from "react-router-dom";
import userContext from "../../Main";
function Login() {
	const {subhash } = useContext(userContext);
	const [loginModel, setLoginModel] = useState(false);
	const Swal = require('sweetalert2')
	const navigate = useNavigate();
	

	function loginBtn() {
		setLoginModel(true);
	}
	function closeModel() {
		setLoginModel(false);
	}


	const [getData, setData] = useState({
		userName: "",
		userPass: ""
	})
	const { userName, userPass } = getData;
	const getUserData = e => {
		setData({ ...getData, [e.target.name]: e.target.value })
	}


	function login(e) {
		let user = "@hrms";
		let pass = "@hrms";

		let emplU = "@employee"
		let emplP = "@employee"
		e.preventDefault();
		if (getData.userName == user && getData.userPass == pass) {
			navigate("/hr-dashboard");
		}else if(getData.userName == emplU && getData.userPass == emplP){
			navigate("/employee-dashboard");
		}
		 else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Username or password not valid.",
			});
		}
	}



	return (
		<>
			<div className="Login">
			<LoginHeader />

				<Container>
					<Row>
						<Col className="col-md-6 col-sm-12 col-12 colLoginSec">
							<div className="welCome animate__animated  animate__fadeInDown">Welcome to{subhash} <span className="hrmsTex">HR</span> Management System</div>
							<p className="dearUser animate__animated animate__fadeInDown animate__delay-1s">Dear user/employee you can login your account from here if you have don't account please contact to HR department</p>
							<Marquee className="animateText dearUser animate__animated animate__fadeInDown animate__delay-2s">
								Coming event celebrations happy Diwali 2024.
							</Marquee>
							<div onClick={loginBtn} className="loginBtn shadow-lg dearUser animate__animated animate__zoomIn"><LockOpenIcon /> Login <img className="" src="./imgs/logIn.gif" /></div>
						</Col>
						<Col className="col-md-6 col-sm-12 col-12 sliderCol">
							<Carousel className="mt-5 animate__animated animate__pulse">
								<Carousel.Item>
									<div className="bannerCont">
										<img className="" src="./imgs/banner/3.svg" />
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className="bannerCont">
										<img className="" src="./imgs/banner/2.svg" />
									</div>
								</Carousel.Item>
								<Carousel.Item>
									<div className="bannerCont">
										<img className="" src="./imgs/banner/1.svg" />
									</div>
								</Carousel.Item>
							</Carousel>

						</Col>
					</Row>
				</Container>
				<img className="hrmsIcon vert-move" src="./imgs/hr.png" />
				<img className="hrmsIcon2 vert-move" src="./imgs/r.png" />
				<div className="background"></div>

			</div>

			<div className="someFeatures d-none">
				<h1>Features</h1>
				<p>Some features below for manage employee.</p>
				<Container>
					<Row>
						<Col>
							<div id="testimonial-slider" className="owl-carousel">
								<div className="testimonial">
									<div className="dataFeatures">
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/employee.gif" /></div>
											<div className="headingTxt">Employees Manage</div>
											<div className="runingText">Manage employee easaly</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/work.gif" /></div>
											<div className="headingTxt">Work Allocated</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/report.gif" /></div>
											<div className="headingTxt">Report Builder</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
									</div>
								</div>
								<div className="testimonial">
									<div className="dataFeatures">
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/employee.gif" /></div>
											<div className="headingTxt">Employees Manage</div>
											<div className="runingText">Manage employee easaly</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/work.gif" /></div>
											<div className="headingTxt">Work Allocated</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/report.gif" /></div>
											<div className="headingTxt">Report Builder</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
									</div>
								</div>
								<div className="testimonial">
									<div className="dataFeatures">
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/employee.gif" /></div>
											<div className="headingTxt">Employees Manage</div>
											<div className="runingText">Manage employee easaly</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/work.gif" /></div>
											<div className="headingTxt">Work Allocated</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
										<div className="mainDiv shadow-sm btn1">
											<div className="iconsBox"><img src="./imgs/report.gif" /></div>
											<div className="headingTxt">Report Builder</div>
											<div className="runingText">Assign work to all employee</div>
										</div>
									</div>
								</div>
							</div>

						</Col>
					</Row>
				</Container >

			</div >
			{/* login pop Sect start */}
			{loginModel &&
				<div className="loginSect">
					<div className="innerCont animate__animated animate__slideInDown">
						<div className="fristCol "><img src="./imgs/login.svg" /></div>
						<div className="loginForm  ">
							<div className="imgBox"><Person3Icon /></div>
							<div className="loginAcct">Login Account</div>
							<form onSubmit={login}>
								<div className="">
									<TextField id="standard-basic" label="Username" variant="standard" fullWidth type="text"
										value={userName}
										name="userName"
										required
										autoComplete="off"
										onChange={e => getUserData(e)}
									/>
								</div>
								<div className="mt-3">
									<TextField id="standard-basic" label="Password" variant="standard" fullWidth type="password"
										value={userPass}
										name="userPass"
										required
										autoComplete="off"
										onChange={e => getUserData(e)}
									/>
								</div>
								<div className="buttonSect">
									<div>
										<label >
											<input type="checkbox" id="remb" /> Remember
										</label>
									</div>
									<div className="forGot">Forgot password?</div>
								</div>
								<div className="btnSec">
									<button type="submit" className="btn btn-success">Login</button>
								</div>
							</form>
						</div>
						<CloseIcon className="closeBtns" onClick={closeModel} />
					</div>
				</div>
			}
			{/* login pop Sect ebd */}
		</>
	);
}

export default Login;
