import AdminPanelSettingsSharpIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import Emplnotification from './Emplnotification';
import EmploDetails from './EmploDetails';

function EmployeeHeader() {
	return (
		<>
			<div className="emplheaderBck shadow-sm">
				<div className="rowHeaders">
					<div className="empldashBoardText"><AdminPanelSettingsSharpIcon />Dashboard </div>
					<div className="userDetails">
						<div><EmploDetails /></div>
						<Emplnotification />
					</div>
				</div>
			</div>
		</>
	);
}
export default EmployeeHeader;
