import { Link } from "react-router-dom";
import HrHeader from "./HrHeader";
function HrModules() {
	

	return (
		<>
			<div className='hrmodulesBack'>
				<HrHeader/>
				<div className="onImg">
					<div className="cricle_box">
						<img src="../imgs/hrImgs/circle.png" />
						<div className="loader">
							<div className="round"></div>
							<div className="round"></div>
							{/* <div className="round"></div> */}
						</div>
						<div className="criclBack crl1">
							<Link to="/hr-admin">
								<div class="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/admin.gif" />
								</div>
								<div className="textDiv">ADMIN</div>
							</Link>
						</div>

						<div className="criclBack crl2">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/manageUser.gif" />
								</div>
								<div className="textDiv">MANAGE USERS</div>
							</Link>
						</div>
						<div className="criclBack crl3">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/device.gif" />
								</div>
								<div className="textDiv">DEVICE CONFIGURATION</div>
							</Link>
						</div>
						<div className="criclBack crl4">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/shift.gif" />
								</div>
								<div className="textDiv">SHIFT SCHEDULE</div>
							</Link>
						</div>
						<div className="criclBack crl5">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/group.gif" />
								</div>
								<div className="textDiv">GROUP ASSOCIATS</div>
							</Link>
						</div>
						<div className="criclBack crl6">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/attendance.gif" />
								</div>
								<div className="textDiv">TIME & ATTENDANCE</div>
							</Link>
						</div>
						<div className="criclBack crl7">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/leaveTour.gif" />
								</div>
								<div className="textDiv">LEAVE TOUR MANAGE</div>
							</Link>
						</div>
						<div className="criclBack crl8">
							<Link to="">
								<div className="hovicon effect-8 sub-a">
									<img src="../imgs/hrImgs/report.gif" />
								</div>
								<div className="textDiv">REPORT BUILDER</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default HrModules;
