import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CallIcon from '@mui/icons-material/Call';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
function LoginHeader() {
	return (
		<div className="Header">
			<Navbar expand="lg" className="headersTops py-0 shadow-lg " sticky="top">
				<Container fluid className='p-0 animate__animated animate__fadeInDown'>
					<Navbar.Brand href="#">
						<div className='logoBgs'>
							<div className='logoRs'><img src="./imgs/rsplLogo.png" /></div>
						</div>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="navbarScroll" />
					<Navbar.Collapse id="navbarScroll">
						<Nav className="ms-auto my-2 my-lg-0 navBarright ">
							<Nav.Link href=""><HolidayVillageIcon /> Home  </Nav.Link>
							<Nav.Link href=""><ApartmentIcon /> About us</Nav.Link>
							<Nav.Link href=""><FeaturedPlayListIcon /> Features </Nav.Link>
							<Nav.Link href=""><CallIcon />Contact us</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
}
export default LoginHeader;
