import AdminPanelSettingsSharpIcon from '@mui/icons-material/AdminPanelSettingsSharp';
import UserDetails from './UserDetails';
import Hrnotification from './Hrnotification';
function HrHeader() {
	return (
		<>
			<div className="headerBck shadow-sm">
				<div className="rowHeaders">
					<div className="dashBoardText"><AdminPanelSettingsSharpIcon />HR Dashboard </div>
					<div className="userDetails">
						<div><UserDetails /></div>
						<Hrnotification />
					</div>
				</div>
			</div>
		</>
	);
}
export default HrHeader;
