import { Col, Row } from "react-bootstrap";

function EmplPendingApplication() {
	return (
		<>
			<Row>
				<Col className="col-md-6 col-sm-12 col-12">
					<div className='main_wrapper shadow-sm'>
						<div className='header_month'>Pending Application/Authorization</div>
						<div className='table-responsive attend_btl'>
							<table className='table table-bordered'>
								
									<tr className='trRowAtten_2'>
										<td>Leave Application</td>
									</tr>
								
								<tbody>
									<tr>
										<td>4</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Col>
				<Col className="col-md-6 col-sm-12 col-12">
					<div className='main_wrapper shadow-sm'>
						<div className='header_month'>Leave Balance</div>
						<div className='table-responsive attend_btl'>
							<table className='table table-bordered'>

								<tr className='trRowAtten_2'>
									<td>Total C-OFF Balance</td>
									<td>CL : CASUAL LEAVE</td>
									<td>EL : PRIVILEDGE LEAVE</td>
								</tr>

								<tbody>
									<tr>
										<td>0</td>
										<td>0</td>
										<td>0</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
export default EmplPendingApplication;
