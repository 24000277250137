
import './css/EmployeeStyle.css';
import EmployeeHeader from './EmployeeHeader';
import Accordion from 'react-bootstrap/Accordion';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockClockIcon from '@mui/icons-material/LockClock';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import AddchartIcon from '@mui/icons-material/Addchart';
import SendIcon from '@mui/icons-material/Send';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import Marquee from 'react-fast-marquee';
import { Container, Row, Col } from 'react-bootstrap';
import EmplMonthlyAtten from './EmplMonthlyAtte';
import EmplShiftSchedule from './EmplShiftSchedule';
import EmplPendingApplication from './EmplPendingApplication';
import LeaveBlance from './EmpLeaveBalance';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import $ from 'jquery';
import { useState, useRef } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EmploEvents from './EmplEvent';
import EmplHoliday from './EmplHoliday';
import EmplBasicDetails from './EmplBasicDetails';
import EmpDailyAttendance from './EmpDailyAttendance';
import EmpAtteSummary from './EmpAtteSummary';
import EmpShift from './EmpShift';
import EmpOTApplication from './EmpOTApplication';
import EmpLeaveApplication from './EmpLeaveAppli';
import EmpTourAppli from './EmpTourAppli';
import EmpCOFF from './EmpCOFF';
import EmplReport from './EmplReport';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import HomeIcon from '@mui/icons-material/Home';
function EmployeeDash() {
	function menuLeft(pass) {
		let sss = pass;
		if (sss == 1) {
			$(".empleftBar").css("left", "0px");
			$("#onBtn").css("display", "none");
			$("#offBtn").css("display", "block");
		}
		if (sss == 2) {
			$(".empleftBar").css("left", "-250px");
			$("#onBtn").css("display", "block");
			$("#offBtn").css("display", "none");
		}
	}
	const [onPunchBox, setShow] = useState(false);
	const hidePunchboxClose = () => setShow(false);
	const punchBoxShow = () => setShow(true);

	const [empModule, setEmpModule] = useState({
		selecTedData: true,
		eventData: false,
		holiDaySchedule: false,
		basicDetails: false,
		dailyAttenDance: false,
		attenDanceSummary: false,
		shiftSchedule: false,
		otApplication: false,
		leaveBalnace: false,
		leaveApplication: false,
		tourApplication: false,
		cOff: false,
		viewReport: false,
	})
	function leftMenuFun(menu) {
		let getPera = menu;
		if (getPera == "event_btn") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					eventData: true,
					holiDaySchedule: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})
		} else if (getPera == "holiDay") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: true,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})
		}
		else if (getPera == "user_basic_details") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: true,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,

				}
			})

		}
		else if (getPera == "daily_atten") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: true,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})

		}
		else if (getPera == "atten_summary") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: true,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})

		}
		else if (getPera == "shift_Schedule") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: true,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})

		}
		else if (getPera == "ot_application") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: true,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})

		}
		else if (getPera == "leave_balance") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: true,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})
		}
		else if (getPera == "leave_application") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: true,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})
		}
		else if (getPera == "tour_appication") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: true,
					cOff: false,
					viewReport: false,
				}
			})
		}
		else if (getPera == "c_off") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: true,
					viewReport: false,
				}
			})
		}
		else if (getPera == "view_report") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: false,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: true,
				}
			})
		}
		else if (getPera == "home") {
			setEmpModule((oldData) => {
				return {
					...oldData,
					selecTedData: true,
					holiDaySchedule: false,
					eventData: false,
					basicDetails: false,
					dailyAttenDance: false,
					attenDanceSummary: false,
					shiftSchedule: false,
					otApplication: false,
					leaveBalnace: false,
					leaveApplication: false,
					tourApplication: false,
					cOff: false,
					viewReport: false,
				}
			})
		}
		else {
			return false
		}
	}

	// fill report fun
	const [FillReportshow, setFillReportshow] = useState(false);
	const fillReportHide = () => setFillReportshow(false);
	function fillReportFun() {
		setFillReportshow(true);
	}

	// shoot message
	function hideShootMess() { setMessage(false) }
	const [shootMws, setMessage] = useState(false);
	function shootMesage() {
		setMessage(true)
	}

	return (
		<>
			<EmployeeHeader />
			<div className='dropDivs'>
				<div className='empandroidItem'>
					<ArrowCircleRightIcon className='emplmenuIcons' id="onBtn" onClick={() => { menuLeft(1) }} />
					<ArrowCircleLeftIcon className='emplmenuIcons' id="offBtn" onClick={() => { menuLeft(2) }} />
				</div>
				<div className="empleftBar employee_menu" id='leftMenuDiv'>
					<Accordion>
						<span className='noneedArrow'>
							<Accordion.Item eventKey="7" className='acc_btns' onClick={() => { leftMenuFun('home') }}>
								<Accordion.Header > <div className={empModule.home ? 'active_menu' : 'btn_div'}><HomeIcon /> Home</div></Accordion.Header>
							</Accordion.Item>
						</span>

						<Accordion.Item eventKey="0" className='acc_btns'>
							<Accordion.Header cssClass='e-caret-hide'> <div className='btn_div'><AccountCircleIcon /> Basic</div></Accordion.Header>
							<Accordion.Body className="p-0">
								<ul className='listDet'>
									<li className={empModule.eventData ? 'active_menu' : ''} onClick={() => { leftMenuFun('event_btn') }}>Event</li>
									<li className={empModule.holiDaySchedule ? 'active_menu' : ''} onClick={() => { leftMenuFun('holiDay') }}>Holiday Schedule</li>
									<li className={empModule.basicDetails ? 'active_menu' : ''} onClick={() => { leftMenuFun('user_basic_details') }}>User Profile  </li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1" className='acc_btns'>
							<Accordion.Header > <div className='btn_div'><LockClockIcon /> Time Attendance</div></Accordion.Header>
							<Accordion.Body className='p-0'>
								<ul className='listDet'>
									<li className={empModule.dailyAttenDance ? 'active_menu' : ''} onClick={() => { leftMenuFun('daily_atten') }}>Daily Attendance</li>
									<li className={empModule.attenDanceSummary ? 'active_menu' : ''} onClick={() => { leftMenuFun('atten_summary') }}>Attendance Summary</li>
									{/* <li>Attendance Details</li> */}
									<li className={empModule.shiftSchedule ? 'active_menu' : ''} onClick={() => { leftMenuFun('shift_Schedule') }}>Shift Schedule</li>
									<li className={empModule.otApplication ? 'active_menu' : ''} onClick={() => { leftMenuFun('ot_application') }}>Advance Overtime Application</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2" className='acc_btns'>
							<Accordion.Header > <div className='btn_div'><RoomPreferencesIcon /> Leave Management</div></Accordion.Header>
							<Accordion.Body className='p-0'>
								<ul className='listDet'>
									<li className={empModule.leaveBalnace ? 'active_menu' : ''} onClick={() => { leftMenuFun('leave_balance') }}>Leave Balance</li>
									<li className={empModule.leaveApplication ? 'active_menu' : ''} onClick={() => { leftMenuFun('leave_application') }}>Leave Application</li>
									<li className={empModule.tourApplication ? 'active_menu' : ''} onClick={() => { leftMenuFun('tour_appication') }}>Tour Application</li>
									<li className={empModule.cOff ? 'active_menu' : ''} onClick={() => { leftMenuFun('c_off') }}>C-OFF Application</li>
								</ul>
							</Accordion.Body>
						</Accordion.Item>
						<span className='noneedArrow'>
							<Accordion.Item eventKey="4" className='acc_btns' onClick={punchBoxShow}>
								<Accordion.Header > <div className='btn_div'><MarkChatReadIcon /> Mark Attendance </div></Accordion.Header>
							</Accordion.Item>
							<Accordion.Item eventKey="3" className='acc_btns headBtns' onClick={fillReportFun}>
								<Accordion.Header > <div className='btn_div'><AddchartIcon /> Fill daily work report</div></Accordion.Header>
							</Accordion.Item>

							<Accordion.Item eventKey="6" className='acc_btns headBtns' onClick={() => { leftMenuFun('view_report') }}>
								<Accordion.Header > <div className={empModule.viewReport ? 'active_menu' : 'btn_div'}><StackedBarChartIcon /> View Report</div></Accordion.Header>
							</Accordion.Item>

							<Accordion.Item eventKey="5" className='acc_btns' onClick={shootMesage}>
								<Accordion.Header > <div className='btn_div'><SendIcon /> Shoot Message</div></Accordion.Header>
							</Accordion.Item>
						</span>

					</Accordion>
				</div>
				<Container fluid>
					<div className='emp_rightcolm '>
						{empModule.selecTedData &&
							<>
								<div className=''>
									<Row>
										<Col className='col-md-6 col-sm-6 col-12 '>
											<EmplMonthlyAtten />
										</Col>
										<Col className='col-md-6 col-sm-6 col-12'>
											<EmplShiftSchedule />
										</Col>
									</Row>
									<Row>
										<Col>
											<EmplPendingApplication />
										</Col>
									</Row>
								</div>

							</>
						}
						<div className='show_tabData'>
							{empModule.eventData && <EmploEvents />}
							{empModule.holiDaySchedule && <EmplHoliday />}
							{empModule.basicDetails && <EmplBasicDetails />}
							{empModule.dailyAttenDance && <EmpDailyAttendance />}
							{empModule.attenDanceSummary && <EmpAtteSummary />}
							{empModule.shiftSchedule && <EmpShift />}
							{empModule.otApplication && <EmpOTApplication />}
							{empModule.leaveBalnace && <LeaveBlance />}
							{empModule.leaveApplication && <EmpLeaveApplication />}
							{empModule.tourApplication && <EmpTourAppli />}
							{empModule.cOff && <EmpCOFF />}
							{empModule.viewReport && <EmplReport />}
						</div>
						<div className='eventNoti'>
							<Marquee pauseOnHover> NOTICE:	Click here for more detail.	NEWS:	Click here for more detail.	General:	Click here for more detail.</Marquee>
						</div>
					</div>


				</Container>

				{/* punching */}
				<Offcanvas show={onPunchBox} onHide={hidePunchboxClose}>
					<Offcanvas.Header closeButton className='emplMarkAttenHeader shadow-sm'>
						Mark Attendance
					</Offcanvas.Header>
					<Offcanvas.Body>
						<div className='imgpunch'>
							<img src='./imgs/employee/fingerprint-animate.svg' />
						</div>
						<hr />
						<div className='rowSect'>
							<div className="punchBtn btn-lg">
								REGULAR-IN
							</div>
							<div className="punchBtn btn-lg">
								REGULAR-OUT
							</div>
						</div>
						<div className='rowSect'>
							<div className="punchBtn btn-lg">
								OFFICIAL WORK-IN
							</div>
							<div className="punchBtn btn-lg">
								OFFICIAL WORK-OUT
							</div>
						</div>
						<div className='rowSect'>
							<div className="punchBtn btn-lg">
								BREAK START
							</div>
							<div className="punchBtn btn-lg">
								BREAK END
							</div>
						</div>
						<div className='rowSect'>
							<div className="punchBtn btn-lg">
								OVERTIME-IN
							</div>
							<div className="punchBtn btn-lg">
								OVERTIME-OUT
							</div>
						</div>
						<div className='rowSect'>
							<div className="punchBtn btn-lg">
								SHORT LEAVE-IN
							</div>
							<div className="punchBtn btn-lg">
								SHORT LEAVE-OUT
							</div>
						</div>
					</Offcanvas.Body>
				</Offcanvas>
				{/* punching end */}


				{/* fill report */}
				<Offcanvas show={FillReportshow} onHide={fillReportHide}>
					<Offcanvas.Header closeButton className='emplMarkAttenHeader shadow-sm'>
						Fill daily work report
					</Offcanvas.Header>
					<Offcanvas.Body className='report_back'>
						<form>
							<div className='inputesRow'>
								<FormControl size='small' fullWidth>
									<InputLabel id="demo-simple-select-label">Select Project</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Select Project"
									>
										<MenuItem value={1}>LMS</MenuItem>
										<MenuItem value={2}>LMS-V2</MenuItem>
										<MenuItem value={3}>Web-Support</MenuItem>
										<MenuItem value={4}>Mobile Application</MenuItem>
										<MenuItem value={5}>HRMS</MenuItem>
										<MenuItem value={6}>E-Learning</MenuItem>
										<MenuItem value={6}>Other Work</MenuItem>

									</Select>
								</FormControl>
							</div>
							<div className='inputesRow'>
								<LocalizationProvider dateAdapter={AdapterDayjs} fullWidth >
									<DemoContainer components={['DatePicker']} style={{ width: "100%" }}>
										<DatePicker sx={{ width: "100% " }} label="Date" slotProps={{ textField: { size: 'small' } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
							<div className='inputesRow'>
								<FormControl size='small' fullWidth>
									<InputLabel id="demo-simple-select-label">Status</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Status"
									>
										<MenuItem value={1}>Completed</MenuItem>
										<MenuItem value={2}>In Progress</MenuItem>
										<MenuItem value={3}>Not started yet</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='inputesRow'>
								<TextField
									id="standard-multiline-static"
									label="Remark"
									rows={4}
									multiline
									size='small' fullWidth
								/>
							</div>
							<div className='submitButt'>
								<Button type='submit' variant="contained"><SendIcon />Submit</Button>
							</div>
						</form>
					</Offcanvas.Body>
				</Offcanvas>
				{/* fill report end */}


				{/* shoot message start */}

				<Offcanvas show={shootMws} onHide={hideShootMess}>
					<Offcanvas.Header closeButton className='shootMeHeader'>
						Shoot Message
					</Offcanvas.Header>
					<Offcanvas.Body>
						<form>
							<div className='inputesRow'>
								<FormControl size='small' fullWidth>
									<InputLabel id="demo-simple-select-label">Message To</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="Message To"
									>
										<MenuItem value={1}>HR</MenuItem>
										<MenuItem value={2}>Team Head (Manager)</MenuItem>
										<MenuItem value={3}>Ram Krishna</MenuItem>
										<MenuItem value={4}>Subhash Chand</MenuItem>
										<MenuItem value={5}>Raj Singh</MenuItem>
										<MenuItem value={6}>Saurabh Shrama</MenuItem>
										<MenuItem value={7}>Hari Krishan Patn</MenuItem>
										<MenuItem value={8}>Ajay Singh</MenuItem>
										<MenuItem value={9}>Dhrmendra</MenuItem>
										<MenuItem value={10}>Chirag Bhrati</MenuItem>
										<MenuItem value={11}>Tisha Goel</MenuItem>
										<MenuItem value={12}>Aakash Saini</MenuItem>
										<MenuItem value={13}>Bhrat Negi</MenuItem>
										<MenuItem value={14}>Deepak Shukla</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='inputesRow'>
								<TextField
									id="standard-multiline-static"
									label="Message"
									rows={4}
									multiline
									size='small' fullWidth
								/>
							</div>
							<div className='submitButt'>
								<Button type='submit' variant="contained"><SendIcon /> Send</Button>
							</div>
						</form>
					</Offcanvas.Body>
				</Offcanvas>

				{/* shoot message end */}



			</div>

		</>
	);
}
export default EmployeeDash;
