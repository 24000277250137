
import Table from 'react-bootstrap/Table';
import ListIcon from '@mui/icons-material/List';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function EmpShift() {
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<div className='menHeading_2'>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Select Month</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Select Month"
								>
									<MenuItem value={1}>January</MenuItem>
									<MenuItem value={2}>February</MenuItem>
									<MenuItem value={3}>March</MenuItem>
									<MenuItem value={4}>April</MenuItem>
									<MenuItem value={5}>May</MenuItem>
									<MenuItem value={6}>June</MenuItem>
									<MenuItem value={7}>July</MenuItem>
									<MenuItem value={8}>August</MenuItem>
									<MenuItem value={9}>September</MenuItem>
									<MenuItem value={10}>October</MenuItem>
									<MenuItem value={11}>November</MenuItem>
									<MenuItem value={12}>December</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Select Year</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Select Year"
								>
									<MenuItem value={1}>2014</MenuItem>
									<MenuItem value={2}>2015</MenuItem>
									<MenuItem value={3}>2016</MenuItem>
									<MenuItem value={4}>2017</MenuItem>
									<MenuItem value={5}>2018</MenuItem>
									<MenuItem value={6}>2019</MenuItem>
									<MenuItem value={7}>2020</MenuItem>
									<MenuItem value={8}>2021</MenuItem>
									<MenuItem value={9}>2022</MenuItem>
									<MenuItem value={10}>2023</MenuItem>
									<MenuItem value={11}>2024</MenuItem>
								</Select>
							</FormControl>
						</div>
						<Button variant="contained" color="info" className=''>
							View <VisibilityIcon />
						</Button>

					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte mt-3 table-responsive'>
						<center>coming soon</center>
					</div>
				</div>
			</div>
		</>
	);
}
export default EmpShift;
