
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Row, Col, Table } from 'react-bootstrap';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TextField from '@mui/material/TextField';
function EmpOTApplication() {
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<Row>
						<Col className="col-md-5 col-sm-12 col-12">
							<div className='leftSideCol'>
								<p>Advance Overtime Application</p>
								<form>
									<div className='dataDiv'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer required components={['DatePicker']}>
												<DatePicker fullWidth label="Attendance Date" slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
											</DemoContainer>
										</LocalizationProvider>
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic" required fullWidth label="OT Hours" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic" multiline required fullWidth label="Reason" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic" multiline required fullWidth label="Address" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic" type='number' multiline required fullWidth label="Contact" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<button type='submit' className='btn btn-outline-success'>Submit</button>{" "}
										<button className='btn btn-outline-danger'>Cancel</button>
									</div>
								</form>
							</div>
						</Col>
						<Col className="col-md-7 col-sm-12 col-12">
							<div className='toHeaderLeaveAcc'>
								<div>0 Pending</div>
								<div>0 Approved</div>
								<div>0 Rejected</div>
							</div>
							<Table responsive hover bordered>
								<thead>
									<tr>
										<th>OT Date</th>
										<th>Applied OT Hours</th>
										<th>Approved OT Hours</th>
										<th>Application Date</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>20/12/2023</td>
										<td>02:30</td>
										<td>02:30</td>
										<td>20/12/2023</td>
										<td><button className='btn btn-outline-primary btn-sm'>Pending</button></td>
									</tr>
									<tr>
										<td>22/12/2023</td>
										<td>02:30</td>
										<td>02:30</td>
										<td>20/12/2023</td>
										<td><button className='btn btn-outline-success btn-sm'>Approved</button></td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>

				</div>
			</div>
		</>
	);
}
export default EmpOTApplication;
