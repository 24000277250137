
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Row, Col, Table } from 'react-bootstrap';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TextField from '@mui/material/TextField';
function EmpLeaveApplication() {
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<Row>
						<Col className="col-md-5 col-sm-12 col-12">
							<div className='leftSideCol'>
								<p>Leave Application</p>
								<form>
									<div className='dataDiv'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer required components={['DatePicker']}>
												<DatePicker fullWidth label="From" slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
											</DemoContainer>
										</LocalizationProvider>
									</div>
									<div className='dataDiv'>
										<FormControl sx={{ m: 1, }} size="small" style={{ margin: "0" }} fullWidth>
											<InputLabel id="demo-simple-select-standard-label">Select Days</InputLabel>
											<Select
												label="Select Day"
											>
												<MenuItem value={10}>Full day</MenuItem>
												<MenuItem value={20}>1st Half</MenuItem>
												<MenuItem value={5}>2st Half</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='dataDiv'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer required components={['DatePicker']}>
												<DatePicker fullWidth label="To" slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
											</DemoContainer>
										</LocalizationProvider>
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic" inputProps={{ readOnly: true }} fullWidth label="Applied Duration" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
									<FormControl sx={{ m: 1, }} size="small" style={{ margin: "0" }} fullWidth>
											<InputLabel id="demo-simple-select-standard-label">Select Leave</InputLabel>
											<Select
												label="Select Days"
											>
												<MenuItem value={10}>CL</MenuItem>
												<MenuItem value={20}>EL</MenuItem>
												<MenuItem value={51}>UL</MenuItem>
											</Select>
										</FormControl>
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic"
										  required fullWidth inputProps={{ readOnly: true }}  label="Current Balance" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<TextField id="outlined-basic"
										 multiline required
										 fullWidth rows={3} label="Reason" variant="outlined" size='small' />
									</div>
									<div className='dataDiv'>
										<button type='submit' className='btn btn-outline-success'>Submit</button>{" "}
										<button className='btn btn-outline-danger'>Cancel</button>
									</div>
								</form>
							</div>
						</Col>
						<Col className="col-md-7 col-sm-12 col-12">
							<div className='toHeaderLeaveAcc'>
								<div>1 Pending</div>
								<div>2 Approved</div>
								<div>1 Rejected</div>
							</div>
							<Table responsive hover bordered>
								<thead>
									<tr>
										<th>From</th>
										<th>To</th>
										<th>Leave</th>
										<th>Application Date</th>
										<th>Application Type</th>
										<th>Status</th>

									</tr>
								</thead>
								<tbody>
									<tr>
										<td>15/04/2024</td>
										<td>15/04/2024</td>
										<td>UL</td>
										<td>15/04/2024</td>
										<td>New</td>
										<td><button className='btn btn-outline-primary btn-sm'>Pending</button></td>
									</tr>
									<tr>
										<td>15/04/2024</td>
										<td>15/04/2024</td>
										<td>UL</td>
										<td>15/04/2024</td>
										<td>New</td>
										<td><button className='btn btn-outline-success btn-sm'>Approved</button></td>
									</tr>
									<tr>
										<td>15/04/2024</td>
										<td>15/04/2024</td>
										<td>UL</td>
										<td>15/04/2024</td>
										<td>New</td>
										<td><button className='btn btn-outline-success btn-sm'>Approved</button></td>
									</tr>
									<tr>
										<td>15/04/2024</td>
										<td>15/04/2024</td>
										<td>UL</td>
										<td>15/04/2024</td>
										<td>New</td>
										<td><button className='btn btn-outline-danger btn-sm'>Rejected</button></td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>

				</div>
			</div>
		</>
	);
}
export default EmpLeaveApplication;
