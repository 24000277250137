import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useContext, useEffect } from 'react'
import './App.css';
import './Media.css';
import 'animate.css';
import { Routes, Route } from "react-router-dom";
import Login from './componets/login/Login';
import PageNot from './PageNot';
import HrIndex from './componets/hr/HrIndex';
import HrAdmin from './componets/hr/HrAdmin';
import EmployeeDash from './componets/employee/EmployeeDashBoard';
function App() {
	return (
		<div className="App">
			<Routes>
				{/* login Routes start */}
				<Route path='/' element={<Login />} />
				{/* login Routes end */}

				{/* HR Routes start */}
				<Route path='/hr-dashboard' element={<HrIndex />} />
				<Route path='/hr-admin' element={<HrAdmin />} />
				{/* HR Routes end */}

				{/* Employee Routes start */}
				<Route path='/employee-dashboard' element={<EmployeeDash />} />
				{/* Employee Routes end */}

				<Route path='*' element={<PageNot />} />
			</Routes>
		</div>
	);
}

export default App;
