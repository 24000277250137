
import './css/HrStyle.css';
import HrModules from './HrModules';
function HrIndex() {
	return (
		<>
			<HrModules/>
		</>
	);
}
export default HrIndex;
