import { useState, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from 'react-bootstrap/Table';
import ListIcon from '@mui/icons-material/List';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
function EmploEvents() {
	const inputBox = useRef(null);
	const table = useRef(null);

	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<div className='menHeading'>
						<div className='printHeading'>Event</div>
						<div className='secondCol'>
							<input className="form-control d-none" ref={inputBox} onKeyUp={myFunction} type="text" placeholder="Search..." />
							<div className='field'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm" requre label="Start Date" slotProps={{ textField: { size: 'small' } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
							<div className='field'>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DemoContainer components={['DatePicker']}>
										<DatePicker size="sm"  label="End Date" slotProps={{ textField: { size: 'small' } }} />
									</DemoContainer>
								</LocalizationProvider>
							</div>
							<Button variant="contained" color="info" className='mt-2'>
								View <VisibilityIcon />
							</Button>
						</div>
					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte'>
						<Table hover bordered responsive align='' ref={table}>
							<thead>
								<tr>
									<th>Sr.N.</th>
									<th>Date-Time</th>
									<th>I/O</th>
									<th>Access</th>
									<th>Source</th>
									<th>Status</th>
									<th>location</th>
									<th>View Image</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody >
								<tr>
									<td>1</td>
									<td>08/03/2024 09:15</td>
									<td>Entry</td>
									<td>Allowed</td>
									<td>Device</td>
									<td>Authorized</td>
									<td>Delhi</td>
									<td><AccountCircleIcon /></td>
									<td><ListIcon /></td>
								</tr>
								<tr>
									<td>2</td>
									<td>08/03/2024 09:15</td>
									<td>Entry</td>
									<td>Allowed</td>
									<td>Device</td>
									<td>Authorized</td>
									<td>Delhi</td>
									<td><AccountCircleIcon /></td>
									<td><ListIcon /></td>
								</tr>
								<tr>
									<td>3</td>
									<td>08/03/2024 09:15</td>
									<td>Entry</td>
									<td>Allowed</td>
									<td>Device</td>
									<td>Authorized</td>
									<td>Delhi</td>
									<td><AccountCircleIcon /></td>
									<td><ListIcon /></td>
								</tr>
								<tr>
									<td>4</td>
									<td>08/03/2024 09:15</td>
									<td>Entry</td>
									<td>Allowed</td>
									<td>Device</td>
									<td>Authorized</td>
									<td>Delhi</td>
									<td><AccountCircleIcon /></td>
									<td><ListIcon /></td>
								</tr>
								<tr>
									<td>5</td>
									<td>08/03/2024 09:15</td>
									<td>Entry</td>
									<td>Allowed</td>
									<td>Device</td>
									<td>Authorized</td>
									<td>Delhi</td>
									<td><AccountCircleIcon /></td>
									<td><ListIcon /></td>
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	);
}
export default EmploEvents;
