import { useState, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Table from 'react-bootstrap/Table';
import ListIcon from '@mui/icons-material/List';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function EmpDailyAttendance() {



	return (
		<>
			<div className='show_tabData '>
				<div className='animate__animated  animate__bounceInDown'>
					<div className='menHeading_2'>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Select Month</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Select Month"
								>
									<MenuItem value={1}>January</MenuItem>
									<MenuItem value={2}>February</MenuItem>
									<MenuItem value={3}>March</MenuItem>
									<MenuItem value={4}>April</MenuItem>
									<MenuItem value={5}>May</MenuItem>
									<MenuItem value={6}>June</MenuItem>
									<MenuItem value={7}>July</MenuItem>
									<MenuItem value={8}>August</MenuItem>
									<MenuItem value={9}>September</MenuItem>
									<MenuItem value={10}>October</MenuItem>
									<MenuItem value={11}>November</MenuItem>
									<MenuItem value={12}>December</MenuItem>
								</Select>
							</FormControl>
						</div>
						<div className='field'>
							<FormControl size='small' fullWidth style={{ width: "100%" }}>
								<InputLabel id="demo-simple-select-label" >Select Year</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									label="Select Year"
								>
									<MenuItem value={1}>2014</MenuItem>
									<MenuItem value={2}>2015</MenuItem>
									<MenuItem value={3}>2016</MenuItem>
									<MenuItem value={4}>2017</MenuItem>
									<MenuItem value={5}>2018</MenuItem>
									<MenuItem value={6}>2019</MenuItem>
									<MenuItem value={7}>2020</MenuItem>
									<MenuItem value={8}>2021</MenuItem>
									<MenuItem value={9}>2022</MenuItem>
									<MenuItem value={10}>2023</MenuItem>
									<MenuItem value={11}>2024</MenuItem>
								</Select>
							</FormControl>
						</div>
						<Button variant="contained" color="info" className=''>
							View <VisibilityIcon />
						</Button>

					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte mt-3 table-responsive'>
						<Table hover valign="middle" bordered align='center' className='emplAddTbls'>
							<thead className='trHead' style={{ position: "sticky", top: "0" }}>
								<tr>
									<th>Date</th>
									<th>Shift</th>
									<th>First IN</th>
									<th>Last OUT</th>
									<th>1st Half</th>
									<th>2nd Half</th>
									<th>Late In</th>
									<th>Early-OUT</th>
									<th>Work Hours</th>
									<th>Extra Work</th>
									<th>Net-Work</th>
									<th>Break Hours</th>
									<th>Generated Overtime</th>
									<th>Authorized Overtime</th>
									<th>Remark	</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody >
								<tr valign="middle">
									<td>01/04/2024</td>
									<td>G9</td>
									<td>09:17</td>
									<td>18:06</td>
									<td><button className='btn btn-outline-success btn-sm'>PR</button></td>
									<td><button className='btn btn-outline-success btn-sm'>PR</button></td>
									<td></td>
									<td></td>
									<td>08:19</td>
									<td>00:19</td>
									<td>08:19</td>
									<td>00:30</td>
									<td></td>
									<td></td>
									<td></td>
									<td><ListIcon /></td>
								</tr>
								<tr valign="middle">
									<td>02/04/2024</td>
									<td>G9</td>
									<td>09:17</td>
									<td>18:06</td>
									<td><button className='btn btn-outline-success btn-sm'>PR</button></td>
									<td><button className='btn btn-outline-danger btn-sm'>AB</button></td>
									<td></td>
									<td></td>
									<td>08:19</td>
									<td>00:19</td>
									<td>08:19</td>
									<td>00:30</td>
									<td></td>
									<td></td>
									<td></td>
									<td><ListIcon /></td>
								</tr>
								{/* footer */}
								<tr className='trHead' style={{ position: "sticky", bottom: "0" }}>
									<th>Total</th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th>0:30</th>
									<th></th>
									<th>50:11</th>
									<th>02:30</th>
									<th>46:20</th>
									<th>05:30</th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	);
}
export default EmpDailyAttendance;
