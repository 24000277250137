import './css/HrStyle.css';
import '../../Media.css';

import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ReplayIcon from '@mui/icons-material/Replay';
import DvrIcon from '@mui/icons-material/Dvr';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AddchartIcon from '@mui/icons-material/Addchart';
import ExtensionIcon from '@mui/icons-material/Extension';
import { Link } from 'react-router-dom';
function QuickLinks() {
	return (
		<>
			<div className='rightBox p-2'>
				<div className='row g-0 quickLink p-2 px-1 shadow-sm border'>
					<div className='col d-inline-flex align-items-center px-1'>Quick Links</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-primary btn-sm'><AddIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-info btn-sm'><CalendarMonthIcon /></button>
					</div>
					<div className='col-auto px-1'>
						<button className='btn btn-outline-danger btn-sm backBtn'><Link to='/hr-dashboard'><ReplayIcon /></Link></button>
					</div>
				</div>
				<div className='row g-0 hr_rowBtnSectio text-center mt-2'>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><DvrIcon /></div>
							System Account
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><TravelExploreIcon /></div>
							Global Policy
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><NearbyErrorIcon /></div>
							Alert Message Con..
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><PublishedWithChangesIcon /></div>
							Import Data
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><CallMissedOutgoingIcon /></div>
							Export Data
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><EditCalendarIcon /></div>
							Task Scheduler
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><AddchartIcon /></div>
							Report Scheduler
						</div>
					</div>
					<div className='col-lg-2 col-md-3 col-sm-4 col-12 px-1 my-2'>
						<div className='border border-2 px-0 py-3 mainBtn' role='button'>
							<div className='iconsCricle mb-1 shadow-sm'><ExtensionIcon /></div>
							Activity long
						</div>
					</div>
				</div>
			</div>
			<div className='clear'></div>
		</>
	);
}
export default QuickLinks;
