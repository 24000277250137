function PageNot() {
	return (
		<div className="notFound">
			<div><img src="./imgs/no.svg"/></div>
			<a className="btn btn-warning shadow-lg btn-lg" href="/">Go Back</a>
		</div>
	);
}

export default PageNot;
