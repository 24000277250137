import UserDetails from './UserDetails';
import './css/HrStyle.css';
import '../../Media.css';
import Accordion from 'react-bootstrap/Accordion';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import PreviewIcon from '@mui/icons-material/Preview';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DvrIcon from '@mui/icons-material/Dvr';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import $ from 'jquery';
import QuickLinks from './QuickLinks';
import { useState } from 'react';
function HrAdmin() {
	function menuLeft(id) {
		if (id == 1) {
			$(".hrleftMenu").css('display', 'block').animate({ "left": "0" }, 5);
			$("#onBtn").css("display", "none");
			$("#offBtn").css("display", "block");
		}
		else {
			$(".hrleftMenu").css('display', 'none').animate({ "left": "-260px" }, 5);
			$("#onBtn").css("display", "block");
			$("#offBtn").css("display", "none");
		}
	}
	const [hrMuduls, sethrMuduls] = useState({
		selectedData: true,
	})

	return (
		<>
			<div className="headerBck shadow-sm border-bottom border-secondary">
				<div className="rowHeaders">
					<div className='hrandroidItem'>
						<ArrowCircleRightIcon className='hrmenunuIcons' id="onBtn" onClick={() => { menuLeft(1) }} />
						<ArrowCircleLeftIcon className='hrmenunuIcons' id="offBtn" onClick={() => { menuLeft(2) }} />
					</div>
					<div className="dashBoardText"><AdminPanelSettingsIcon /> HR Admin</div>
					<div className="userDetails">
						<UserDetails />
					</div>
				</div>
			</div>
			<section>
				<div className='hrleftMenu'>
					<div className='d-flex border-bottom border-secondary text-white py-2 px-1 menubtn' role='button'>
						<div className='iconsDiv'><DvrIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>Home</div>
					</div>
					<div className='d-flex border-bottom border-secondary text-white py-2 px-1 menubtn' role='button'>
						<div className='iconsDiv'><DvrIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>System Account</div>
					</div>
					<div className='d-flex border-bottom border-secondary text-white text-nowrap menubtn' role='button'>
						<Accordion className='w-100 p-0' flush>
							<Accordion.Item className='border-bottom border-secondary' eventKey="0">
								<Accordion.Header><div className='iconsDiv'><InstallDesktopIcon /></div><div className='textHide'>System Configuration</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDet p-0'>
										<li>Gloabl Policy</li>
										<li>Identification Server Configuration</li>
										<li>SMS Configuration</li>
										<li>Email Configuration</li>
										<li>Rename Group</li>
										<li>Enterprise Profile</li>
										<li>Alert Message Configuration</li>
										<li>Custom Message</li>
										<li>Loction Master</li>
										<li>Location Group</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className='border-bottom border-secondary' eventKey="1">
								<Accordion.Header><div className='iconsDiv'><ConnectedTvIcon /></div><div className='textHide'>System Utilities</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDet bgClr'>
										<li>Import Data</li>
										<li>Export Data</li>
										<li className='p-0'>
											<Accordion className='w-100 p-0' flush>
												<Accordion.Item className='border-0' eventKey="2">
													<Accordion.Header><div className='iconsDiv'><InstallDesktopIcon /></div><div className='textHide'>Third Party Export</div></Accordion.Header>
													<Accordion.Body className='p-0'>
														<ul className='listDet'>
															<li className='border-top'>Task Scheduler</li>
															<li>Report Scheduler</li>
															<li>Event Scheduler</li>
															<li>Message Board</li>
															<li>Manage Database</li>
														</ul>
													</Accordion.Body>
												</Accordion.Item>
											</Accordion>
										</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header><div className='iconsDiv'><PreviewIcon /></div><div className='textHide'>Views/Logs</div></Accordion.Header>
								<Accordion.Body className='p-0'>
									<ul className='listDet'>
										<li>Activity Log</li>
										<li>Event View</li>
										<li>Alert View</li>
										<li>Scheduler Log</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					<div className='d-flex border-bottom border-secondary text-white py-2 px-1 menubtn' role='button'>
						<div className='iconsDiv'><RecentActorsIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>License Informations</div>
					</div>
					<div className='d-flex border-bottom border-secondary text-white py-2 px-1 menubtn' role='button'>
						<div className='iconsDiv'><SystemUpdateAltIcon /></div>
						<div className='textHide d-inline-flex align-items-center'>Download Manager</div>
					</div>
				</div>
				{hrMuduls.selectedData &&
					<QuickLinks />
				}
			</section >
		</>
	);
}
export default HrAdmin;
