import { useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { Col, Container, Row } from 'react-bootstrap';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import Accordion from 'react-bootstrap/Accordion';
function EmplBasicDetails() {
	const inputBox = useRef(null);
	const table = useRef(null);

	function myFunction() {
		let filter, tr, tds, i, txtValue;
		filter = inputBox.current.value.toUpperCase();
		tr = table.current.getElementsByTagName("tr");
		console.log(tr);
		for (i = 1; i < tr.length; i++) {
			tds = tr[i].getElementsByTagName("td");
			let isExist = false;
			for (let td of tds) {
				txtValue = td.textContent || td.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					isExist = true;
				}
			}
			if (isExist) {
				tr[i].style.display = "";
			} else {
				tr[i].style.display = "none";
			}
		}
	}
	return (
		<>
			<div className='show_tabData'>
				<div className='animate__animated animate__fadeInDown'>

					<div className='menHeading d-none'>
						<div className='printHeading'>User Basic Details</div>
						<div className='secondCol'>
						</div>
					</div>
					{/* <div className='dateSese'>

					</div> */}
					<div className='table_conte'>
						<Container fluid>
							<Row>
								<Col className='col-md-12 col-12 col-sm-12'>
									<div className='empProBack'>
										<div className='userProDP shadow-sm'>
											<img src='../imgs/u.jpg' />
										</div>
										<div className='sec'>
											<div className='row_dete'>
												<div className='fristColm'>Employee ID</div>
												<div>10017</div>
											</div>
											<div className='row_dete'>
												<div className='fristColm'>Name</div>
												<div>SUBHASH CHAND</div>
											</div>
											<div className='row_dete'>
												<div className='fristColm'>Designation</div>
												<div>UI Developer</div>
											</div>
											<div className='row_dete'>
												<div className='fristColm'>Department</div>
												<div>Forever Books Pvt. Ltd</div>
											</div>
											<div className='footer_pro'>
												<div><EmailIcon /> subhash.chand96254@gmail.com</div>
												<div><CallIcon /> 9958486532</div>
											</div>
										</div>
									</div>
									<div className='contactDetails'>Official Details</div>
									<Table hover bordered responsive>
										<thead>
											<tr className="headerSect">
												<th>Gender</th>
												<th>Blood Group</th>
												<th>Today's Status</th>
												<th>Official Cell</th>
												<th>Phone-Extension</th>
												<th>Email</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Male</td>
												<td>NA</td>
												<td>First Half : IN <br />
													Second Half : Absent<br />
													Last Entry from Door V4-Device-11 at 09:15</td>
												<td>NA</td>
												<td>512</td>
												<td>subhash@swaadhyayan.com</td>
											</tr>
										</tbody>
									</Table>
									<hr/>
									<div className='user_deAcc'>
										<Accordion>
											<Accordion.Item eventKey="0">
												<Accordion.Header>Group Details</Accordion.Header>
												<Accordion.Body className='bg-light'>
													<Table hover bordered responsive>
														<thead>
															<tr className="headerSect">
																<th>Organization</th>
																<th>Branch</th>
																<th>Leave Credit</th>
																<th>Category</th>
																<th>State</th>
																<th>Custom Group 1</th>
																<th>Custom Group 2</th>
																<th>Custom Group 3</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Rachna Sagar Pvt Ltd</td>
																<td>FOREVER</td>
																<td>Full Credit</td>
																<td>Delhi</td>
																<td>ONROLL EMPLOYEE</td>
																<td>Custom Group 1</td>
																<td>Custom Group 2</td>
																<td>Custom Group 3</td>
															</tr>
														</tbody>
													</Table>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header>Reporting Group Details</Accordion.Header>
												
													<Accordion.Body className='bg-light'>
														<Table hover bordered responsive>
															<thead>
																<tr className="headerSect">
																	<th>Group name</th>
																	<th>In-Charge 1</th>
																	<th>In-Charge 2</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>Yashwant Singh and Lalit Sir</td>
																	<td>YASHWANT SINGH</td>
																	<td>NA</td>
																</tr>
															</tbody>
														</Table>
													</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</div>
								</Col>
								<Col className='col-md-4 col-12 col-sm-12 d-none'>
									<input className="form-control searchBox" ref={inputBox} onKeyUp={myFunction} type="text" placeholder="Search user" />
									<div className='userIDData'>
										<Table hover bordered responsive align='' ref={table}>
											<thead>
												<tr>
													<th style={{ width: "50px" }}>Sr.N.</th>
													<th>User's ID</th>
													<th>Name</th>
												</tr>
											</thead>
											<tbody >
												<tr>
													<td>1</td>
													<td>10011</td>
													<td>RAM KRISHAN DWI</td>
												</tr>
												<tr>
													<td>2</td>
													<td>10012</td>
													<td>MOHAN SINGH</td>
												</tr>
												<tr>
													<td>3</td>
													<td>104</td>
													<td>HARI KRISHAN PANT</td>
												</tr>
												<tr>
													<td>4</td>
													<td>105</td>
													<td>SANDEEP SHARMA</td>
												</tr>
												<tr>
													<td>6</td>
													<td>106</td>
													<td>GORAV SHRIVASTA</td>
												</tr>
												<tr>
													<td>7</td>
													<td>107</td>
													<td>HIMANSHU SURYAN</td>
												</tr>
												<tr>
													<td>8</td>
													<td>108</td>
													<td>AKSHYA MISHRA</td>
												</tr>
												<tr>
													<td>9</td>
													<td>109</td>
													<td>SANDEEP SHARMA</td>
												</tr>
												<tr>
													<td>10</td>
													<td>110</td>
													<td>AKSHYA MISHRA</td>
												</tr>
												<tr>
													<td>11</td>
													<td>110</td>
													<td>AKASH SAINI</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>110</td>
													<td>RAJKUMAR SINGH</td>
												</tr>
												<tr>
													<td>12</td>
													<td>10017</td>
													<td>SUBHASH CHAND</td>
												</tr>

											</tbody>
										</Table>
									</div>
								</Col>
							</Row>
						</Container>

					</div>
				</div>
			</div>
		</>
	);
}
export default EmplBasicDetails;
